import './App.css';
import MainRoutes from './components/MainRoutes';

function App() {
  return (
    <div>
      <MainRoutes />
    </div>
  );
}

export default App;
